@font-face {
  font-family: 'satoshi';
  src: url('../src/assets/fonts/Satoshi-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'satoshi';
  src: url('../src/assets/fonts/Satoshi-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'satoshi';
  src: url('../src/assets/fonts/Satoshi-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'satoshi';
  src: url('../src/assets/fonts/Satoshi-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'satoshi';
  src: url('../src/assets/fonts/Satoshi-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}
